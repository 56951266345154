body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@page { 
    size: auto; 
    margin: 0mm;
}
