.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
}

.ReactModalPortal > div {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: all 300ms ease-in-out;
  transform: scale(0);
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  transform: scale(1);
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  transform: scale(0);
}
