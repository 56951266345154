.carouselContent-enter-done {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rc-steps {
  width: unset !important;
  display: block !important;
}

.rc-steps-item {
  margin-right: 12px;
}

.rc-steps-item-description {
  display: flex;
}
