.rt-thead.-header {
    box-shadow: none !important;
}

.pagination-bottom {
    position: fixed!important;
    left: 180px;
    right: 30px;
    bottom: 0px;
    box-shadow: none;
    background: #fff;
}

.order-table .rt-td {
    padding: 0;
    border: none;
}

.addProductTable .pagination-bottom {
    display: none;
}

.ReactTable {
    border-radius: 4px;
    border: none;
}

.ReactTable .rt-noData {
    z-index: 2,
}

.rt-tr-group {
    /*margin: 3px 0px;*/
    background: #fff;
    border-radius: 4px;
    border-bottom: none!important;
    /*border: 1px solid #ddd;*/
    /*border-bottom: 1px solid #ddd!important;*/
    /*box-shadow: rgba(129, 148, 167, 0.39) 0px 3px 10px 0px;*/
}

.ReactTable .-pagination {
    box-shadow: none;
}

.-btn {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}

.-btn:hover {
    background: #e05853 !important;
    color: #fff !important;
}

.-btn:disabled:hover {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}