body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@page { 
    size: auto; 
    margin: 0mm;
}

.recharts-legend-wrapper {
    bottom: -10px !important;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.CalendarDay__selected_span {
    background: rgba(218, 47, 40, .5);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: rgba(218, 47, 40, .8);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: rgba(218, 47, 40, .5);
    border: 1px solid rgba(218, 47, 40, .5);
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid rgb(218, 47, 40);
}

.DateInput {
    position: static;
}

.DateRangePickerInput_clearDates {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateRangePickerInput_clearDates:hover {
    background: none;
    outline: none;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid rgb(218, 47, 40);
}
.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
}

.ReactModalPortal > div {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  -webkit-transform: scale(0);
          transform: scale(0);
}

/* @page {
  size: A4;
  margin-top: 50px;
  margin-bottom: 50px;
} */

.carouselContent-enter-done {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rc-steps {
  width: unset !important;
  display: block !important;
}

.rc-steps-item {
  margin-right: 12px;
}

.rc-steps-item-description {
  display: flex;
}

/* always show scrollbars */
/* 
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.rt-thead.-header {
    box-shadow: none !important;
}

.pagination-bottom {
    position: fixed!important;
    left: 180px;
    right: 30px;
    bottom: 0px;
    box-shadow: none;
    background: #fff;
}

.order-table .rt-td {
    padding: 0;
    border: none;
}

.addProductTable .pagination-bottom {
    display: none;
}

.ReactTable {
    border-radius: 4px;
    border: none;
}

.ReactTable .rt-noData {
    z-index: 2,
}

.rt-tr-group {
    /*margin: 3px 0px;*/
    background: #fff;
    border-radius: 4px;
    border-bottom: none!important;
    /*border: 1px solid #ddd;*/
    /*border-bottom: 1px solid #ddd!important;*/
    /*box-shadow: rgba(129, 148, 167, 0.39) 0px 3px 10px 0px;*/
}

.ReactTable .-pagination {
    box-shadow: none;
}

.-btn {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}

.-btn:hover {
    background: #e05853 !important;
    color: #fff !important;
}

.-btn:disabled:hover {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;
  width: 400px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;

  background: white;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.rt-thead.-header {
    box-shadow: none !important;
}

.pagination-bottom {
    position: fixed!important;
    left: 180px;
    right: 30px;
    bottom: 0px;
    box-shadow: none;
    background: #fff;
}

.order-table .rt-td {
    padding: 0;
    border: none;
}

.addProductTable .pagination-bottom {
    display: none;
}

.ReactTable {
    border-radius: 4px;
    border: none;
}

.ReactTable .rt-noData {
    z-index: 2,
}

.rt-tr-group {
    /*margin: 3px 0px;*/
    background: #fff;
    border-radius: 4px;
    border-bottom: none!important;
    /*border: 1px solid #ddd;*/
    /*border-bottom: 1px solid #ddd!important;*/
    /*box-shadow: rgba(129, 148, 167, 0.39) 0px 3px 10px 0px;*/
}

.ReactTable .-pagination {
    box-shadow: none;
}

.-btn {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}

.-btn:hover {
    background: #e05853 !important;
    color: #fff !important;
}

.-btn:disabled:hover {
    background: rgb(218, 47, 40) !important;
    color: #fff !important;
}
.react-confirm-alert-body {
  width: 500px !important;
}

.react-confirm-alert-button-group button {
  width: 50%;
  padding: 16px !important;
  font-size: 18px !important;
}

.CalendarDay__selected_span {
    background: rgba(218, 47, 40, .5);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: rgba(218, 47, 40, .8);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: rgba(218, 47, 40, .5);
    border: 1px solid rgba(218, 47, 40, .5);
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid rgb(218, 47, 40);
}

.DateInput {
    position: static;
}

.DateRangePickerInput_clearDates {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateRangePickerInput_clearDates:hover {
    background: none;
    outline: none;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid rgb(218, 47, 40);
}
/* Pulse keyframe */

.loading-animation.rect-shape,
.loading-animation.round-shape,
.loading-animation.text-row,
.loading-animation .rect-shape,
.loading-animation .round-shape,
.loading-animation .text-row {
  -webkit-animation: react-placeholder-pulse .75s infinite;
  animation: react-placeholder-pulse .75s infinite;
}
