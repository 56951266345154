.CalendarDay__selected_span {
    background: rgba(218, 47, 40, .5);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: rgba(218, 47, 40, .8);
    color: white;
    border: 1px solid rgba(218, 47, 40, .5);
}

.CalendarDay__selected {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    border: 1px solid rgba(218, 47, 40);
    color: white;
}

.CalendarDay__selected:hover {
    background: rgb(218, 47, 40);
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: rgba(218, 47, 40, .5);
    border: 1px solid rgba(218, 47, 40, .5);
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid rgb(218, 47, 40);
}

.DateInput {
    position: static;
}

.DateRangePickerInput_clearDates {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateRangePickerInput_clearDates:hover {
    background: none;
    outline: none;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid rgb(218, 47, 40);
}