/* Pulse keyframe */

.loading-animation.rect-shape,
.loading-animation.round-shape,
.loading-animation.text-row,
.loading-animation .rect-shape,
.loading-animation .round-shape,
.loading-animation .text-row {
  -webkit-animation: react-placeholder-pulse .75s infinite;
  animation: react-placeholder-pulse .75s infinite;
}